import React, { useState } from "react"
import { Card, Form } from "react-bootstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import { Prev } from "react-bootstrap/esm/PageItem"

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  subject: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  message: Yup.string()
    .min(30, "Too Short!")
    .max(2000, "Too Long!")
    .required("Required"),
})

interface Props {
  onClose: () => any
}

const backendUrl = process.env.GATSBY_BACKEND_HOST

const ContactForm = ({ onClose }: Props) => {
  const [requestState, setRequestState] = useState({
    sending: false,
    sent: false,
    success: false,
    error: false,
  })
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setRequestState(prev => ({ ...prev, sending: true }))

      axios
        .post(`${backendUrl}/flapmax/send-mail`, values)
        .then(() => {
          setRequestState({
            sending: false,
            error: false,
            sent: true,
            success: true,
          })
        })
        .catch(err => {
          console.log(err)

          setRequestState({
            sending: false,
            success: false,
            sent: true,
            error: true,
          })
        })
    },
  })

  return (
    <Form as={Card} style={{ maxWidth: "500px", margin: "0 auto" }}>
      <h3 className="mb-3 text-center">Contact Form</h3>
      <p className="mb-5 text-center">
        Hi there, please use this form for any inquiries.
      </p>

      <div className="d-flex gap-2 mb-3">
        <Form.Group>
          {formik.touched.firstName && formik.errors.firstName && (
            <span style={{ color: "red", display: "block" }}>
              {formik.errors.firstName}
            </span>
          )}
          <Form.Label>First name</Form.Label>
          <Form.Control
            type="text"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            placeholder="Enter first name"
            onBlur={formik.handleBlur}
            name="firstName"
            id="firstName"
          />
        </Form.Group>

        <Form.Group>
          {formik.touched.lastName && formik.errors.lastName && (
            <span style={{ color: "red", display: "block" }}>
              {formik.errors.lastName}
            </span>
          )}
          <Form.Label>Last name</Form.Label>
          <Form.Control
            type="text"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter last name"
            name="lastName"
            id="lastName"
          />
        </Form.Group>
      </div>

      <Form.Group className="mb-3">
        {formik.touched.email && formik.errors.email && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.email}
          </span>
        )}
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter email"
          name="email"
          id="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {formik.touched.subject && formik.errors.subject && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.subject}
          </span>
        )}
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter subject"
          name="subject"
          id="subject"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {formik.touched.message && formik.errors.message && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.message}
          </span>
        )}
        <Form.Label>Message</Form.Label>
        <Form.Control
          type="text"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter message"
          as="textarea"
          name="message"
          id="message"
        />
      </Form.Group>

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-secondary" onClick={onClose}>
          Cancel
        </button>

        {requestState.error && (
          <span
            className="text-center px-2"
            style={{ fontSize: "12px", color: "red" }}
          >
            We encountered a problem. Please try again soon or contact us at
            contact@flapmax.com
          </span>
        )}
        <button
          className={`btn ${!requestState.sent && "btn-dark"} ${
            requestState.sent && requestState.success && "btn-success"
          } ${requestState.sent && requestState.error && "btn-danger"}`}
          type="submit"
          disabled={requestState.sent || requestState.sending}
          onClick={() => formik.handleSubmit()}
        >
          {requestState.sent && requestState.success
            ? "Success"
            : requestState.error
            ? "Error"
            : "Submit"}
          {}
        </button>
      </div>
    </Form>
  )
}

export default ContactForm
