import React, { useState } from "react"
import { AiOutlineCluster } from "react-icons/ai"
import { IoIosCellular } from "react-icons/io"
import { GrDocumentImage } from "react-icons/gr"
import { Modal } from "react-bootstrap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/forms/contact"

const turbines = require("../../images/turbines.jpeg")
const brainwire = require("../../images/brainwire.png")

const CoralImagingPage = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <Layout>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <ContactForm onClose={() => setShowModal(false)} />
      </Modal>
      <SEO
        title="Coral Imaging"
        description="A platform for integrating Open Federated Learning as well as various inferencing tools for 3D Image models."
        lang="en"
        meta={[]}
      />

      <div className="full-hero coral heavy-padded d-flex flex-column">
        <div className="d-flex mt-auto justify-content-around">
          <div className="w-100 d-flex justify-content-center align-items-start flex-column slow-fade-rise">
            <h1 className="mb-3 box-shadow-bottom-light no-wrap">
              Coral Imaging
            </h1>
            <h3
              className="box-shadow-bottom-light"
              style={{ maxWidth: "800px" }}
            >
              AI platform for 3D medical imaging over high-speed communication
              networks.
            </h3>
            <button
              className="btn btn-square border-white text-light mt-4"
              onClick={() => setShowModal(true)}
            >
              Contact us
            </button>
          </div>
          <div className="d-none d-lg-block" style={{ paddingLeft: "24px" }}>
            <img
              className="offset-delay-1 slow-fade-rise img-fluid"
              src={brainwire}
              alt=""
            />
          </div>
        </div>
        <div className="d-flex mt-auto align-items-end justify-content-end">
          <p>Coming Soon</p>
        </div>
      </div>

      {/* Content Block */}
      {/* <section>
        <div className="container">
          <h2 className="text-center mb-8">Feature Highlights</h2>
          <div className="row">
            <div className="my-3 col-lg-4">
              <div className="d-flex justify-content-center">
                <GrDocumentImage size={65} />
              </div>
              <h3 className="my-4 text-center">3D Segmentation</h3>
              <p className="text-muted text-center">
                Coral Imaging uses machine learning technology to analyze and
                run segmentation inferences on 3D medical images. You bring your
                image, we segment it for you.
              </p>
            </div>
            <div className="my-3 col-lg-4">
              <div className="d-flex justify-content-center">
                <IoIosCellular size={65} />
              </div>
              <h3 className="my-4 text-center">5G Speeds</h3>
              <p className="text-muted text-center">
                We've utilize blazing fast 5G networking to reduce latency and
                increase productivity on the edge. This means you get more done-
                faster.
              </p>
            </div>{" "}
            <div className="my-3 col-lg-4">
              <div className="d-flex justify-content-center">
                <AiOutlineCluster size={65} />
              </div>
              <h3 className="my-4 text-center">Federated Learning</h3>
              <p className="text-muted text-center">
                Use Coral Imaging to build out and manage your
                multi-institutional federation. From collaborators to
                aggregator, set up and configure your cluster for your needs.
              </p>
            </div>{" "}
          </div>
        </div>
      </section> */}

      {/* Hero Section */}
      {/* <div className="hero-wrapper half background-position-top">
        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
          <h2 className="mb-5">Contact us to learn more or view a demo</h2>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-square white-borders text-light"
              onClick={() => setShowModal(true)}
            >
              Contact us
            </button>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export default CoralImagingPage
